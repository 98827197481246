import React from "react";

const PageNotFound = () => {
  return (
    <div className="pageNotFound">
      <h1>New features in development. Stay tuned.</h1>
    </div>
  );
};

export default PageNotFound;
