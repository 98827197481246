import React, { useEffect, useState } from "react";
import RenderFields from "./RenderFields";
import { Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";

import ActionOptionsTable from "./ActionOptionsTable";
import {
  getChangeOnShipsValue,
  getDisplacementDifferenceCalc,
  getSelectedOptionName,
  getTotalValues,
} from "../../services/commonFunction";
import PropTypes from "prop-types";
import SampleVerificationDetals from "./commonModalForms/SampleVerificationDetals";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
const RenderTableForDraftSurveySection = ({
  section,
  sectionIndex,
  handleFieldChange,
  formData,
  setFormData,
  formErrors,
}) => {
  const [addInterm, setAddInterm] = useState(0);
  const [addFinal, setAddFinal] = useState(false);

  const getSingleCommonfield = (name, type, label = "", options = []) => {
    return (
      <RenderFields
        field={{
          width: 4,
          name: name,
          label: label,
          type: type,
          options: options,
          fieldWidth: 75,
        }}
        sectionIndex={sectionIndex}
        fieldIndex={1 * 100 + 1}
        formData={formData}
        handleFieldChange={handleFieldChange}
        formErrors={formErrors}
        renderTable={true}
        tableIndex={sectionIndex}
      />
    );
  };

  useEffect(() => {
    if (formData[1]?.["opsvd_id"]) {
      if(formData[0]?.opsvd_is_final){
        setAddFinal(true);
      }
      if(formData[0]?.opsvd_interim_count){
        setAddInterm(formData[0]?.opsvd_interim_count);
      }
    }
  }, [formData[1]?.["opsvd_id"]]);
  const header1ShipsValue = getChangeOnShipsValue(
    formData,
    getTotalValues(formData, "initial", section.headers1),
    getTotalValues(formData, "final", section.headers1)
  );
  const header2ShipsValue = getChangeOnShipsValue(
    formData,
    getTotalValues(formData, "initial", section.headers2),
    getTotalValues(formData, "final", section.headers2)
  );

  const onHandleIntrimButton = () => {
    let updatedFormData = { ...formData };
    if (!updatedFormData[1]) {
      updatedFormData[1] = {};
    }
    let existingInterimCount = formData[0]?.opsvd_interim_count;
    existingInterimCount = existingInterimCount ? parseInt(existingInterimCount) + 1 : 1;
    updatedFormData[1]['opsvd_interim_count']=existingInterimCount
    setFormData(updatedFormData);
    setAddInterm(existingInterimCount)
  };

  const onHandleFanalButton = () => {
    let updatedFormData = { ...formData };
    if (!updatedFormData[1]) {
      updatedFormData[1] = {};
    }
    updatedFormData[1]["opsvd_is_final"] = true;
    setFormData(updatedFormData);
    setAddFinal(true);
  };

  return (
    <div key={sectionIndex} className="row my-2 mx-0 bg-white">
      <Card className="Scrollable">
        <CardBody>
          <CardTitle tag="h5">{section.title}</CardTitle>
          <CardSubtitle
            className="mb-2 text-muted draft_table_subtitle"
            tag="h6"
          >
            Quantity On Board On Ship's Account
          </CardSubtitle>
          <table
            className={
              "table table-white responsive borderless no-wrap mt-3 align-middle renderTable draftSurveyRenderTable " +
              (addInterm != 0 && "draftSurveyRenderTable_final")
            }
          >
            <thead>
              <tr>
                <th>Title</th>
                <th>Initial</th>
                {addInterm != 0 && (
                  <>
                    <th>First Interim</th>
                  </>
                )}
                {addFinal && (
                  <>
                    <th>Final</th>
                  </>
                )}

                <th>Units</th>
              </tr>
            </thead>
            <tbody>
              {section.headers1.map((header) => {
                return (
                  <tr>
                    <td>{header.label}</td>
                    <td>
                      {getSingleCommonfield(header.name + "-initial", "number")}
                    </td>
                    {addInterm != 0 && (
                      <>
                        <td>
                          {getSingleCommonfield(
                            header.name + "-interim",
                            "number"
                          )}
                        </td>
                      </>
                    )}
                    {addFinal && (
                      <td>
                        {getSingleCommonfield(header.name + "-final", "number")}
                      </td>
                    )}

                    <td>
                      {getSingleCommonfield(
                        header.name + "-unit",
                        "select",
                        "",
                        ["MTS"]
                      )}
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td>Total</td>
                <td>{getTotalValues(formData, "initial", section.headers1)}</td>
                {addInterm != 0 && (
                  <>
                    <td>
                      {getTotalValues(formData, "interim", section.headers1)}
                    </td>
                  </>
                )}
                {addFinal && (
                  <td>{getTotalValues(formData, "final", section.headers1)}</td>
                )}
                <td>MTS</td>
              </tr>
            </tbody>
          </table>
          <div className="draftSurveyRenderTable_btns_container">
            <table className="draft_bottom_table">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Change on ship's Account</td>
                  <td>{header1ShipsValue}</td>
                </tr>
                <tr>
                  <td>Balance Quantity To Be Discharged</td>
                  <td>{0}</td>
                </tr>
              </tbody>
            </table>
            <div className="submit_btns draftSurveyRenderTable_btns">
                <button
                  type="button"
                  className="saveBtn"
                  id="submit_btn3"
                  onClick={() => onHandleIntrimButton()}
                >
                  Add Interim
                </button>
              {!addFinal && (
                <button
                  type="button"
                  className="saveBtn"
                  id="submit_btn3"
                  onClick={() => onHandleFanalButton()}
                >
                  Add Final
                </button>
              )}
            </div>
          </div>
          <hr />
          <table
            className={
              "table table-white responsive borderless no-wrap mt-3 align-middle renderTable draftSurveyRenderTable " +
              (addInterm != 0 && "draftSurveyRenderTable_final")
            }
          >
            <tbody>
              {section.headers2.map((header) => {
                return (
                  <tr>
                    <td>{header.label}</td>
                    <td>
                      {getSingleCommonfield(header.name + "-initial", "number")}
                    </td>
                    {addInterm != 0 && (
                      <>
                        <td>
                          {getSingleCommonfield(
                            header.name + "-interim",
                            "number"
                          )}
                        </td>
                      </>
                    )}
                    {addFinal && (
                      <td>
                        {getSingleCommonfield(header.name + "-final", "number")}
                      </td>
                    )}

                    <td>
                      {getSingleCommonfield(
                        header.name + "-unit",
                        "select",
                        "",
                        ["MTS"]
                      )}
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td>Total</td>
                <td>{getTotalValues(formData, "initial", section.headers2)}</td>
                {addInterm != 0 && (
                  <>
                    <td>
                      {getTotalValues(formData, "interim", section.headers2)}
                    </td>
                  </>
                )}
                {addFinal && (
                  <td>{getTotalValues(formData, "final", section.headers2)}</td>
                )}
                <td>MTS</td>
              </tr>
            </tbody>
          </table>

          <table className="draft_bottom_table_second">
            <thead>
              <tr>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Difference in Displacement</td>
                <td>
                  {/* {getDisplacementDifferenceCalc(formData)} */}
                  {getSingleCommonfield("opsvd_diffdisplacement", "number", "")}
                </td>
              </tr>
              <tr>
                <td>Change on ship's Account</td>
                <td>{header2ShipsValue}</td>
              </tr>
              <tr>
                <td>Quantity Discharged / Loaded</td>
                <td>
                  {parseInt(formData[1]?.opsvd_diffdisplacement) +
                    parseInt(header2ShipsValue) || 0}{" "}
                  MT
                </td>
              </tr>
              <tr>
                <td>Rounded of Qty.</td>
                <td>{0} MT</td>
              </tr>
            </tbody>
          </table>

          <div className="draft_survey_remarks">
            {getSingleCommonfield("opsvd_remarks", "text", "Remarks")}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

RenderTableForDraftSurveySection.propTypes = {
  section: PropTypes.string,
  sectionIndex: PropTypes.number,
  formData: PropTypes.object,
  handleFieldChange: PropTypes.func,
};

export default RenderTableForDraftSurveySection;
