import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";

const InputSelect = ({ field }) => {
  let {
    name,
    label,
    value,
    onChange,
    required,
    options,

    error,
    fieldWidth,
    multiple,
    placeholder,
    masterOptions,
    customname,
    actionClicked,

    specialClass,
    from,
    viewOnly,
    isCustomOptions,
    customOptions,
    upperClass,
    exludeOptions,
    readOnly,
    handleFieldBlur,
  } = field;

  console.log("Options : ", options);

  const [newOptions, setNewOptions] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const toggleOption = (e, option) => {
    let updatedOptions;
    if (selectedOptions.includes(option)) {
      updatedOptions = selectedOptions.filter((item) => item !== option);
    } else {
      updatedOptions = [...selectedOptions, option];
    }

    value = updatedOptions;
    onChange(updatedOptions);
  };
  useEffect(() => {
    if (isCustomOptions) {
      setNewOptions(customOptions);
    } else {
      masterOptions?.forEach((model, index) => {
        if (model.model === name || model.model === customname) {
          if (exludeOptions && exludeOptions.length > 0) {
            let filteroption = model.data.filter((option) => {
              return !exludeOptions.includes(option.id.toString());
            });
            setNewOptions(filteroption);
          } else {
            setNewOptions(model.data);
          }
        }
      });
    }
  }, [options, newOptions, name, masterOptions]);
  useEffect(() => {
    if (newOptions.length === 1) {
      if (!value) {
        if (name !== "smpl_set_groupjson") {
          onChange(newOptions[0].id, 1)
        }

      }
    }
  }, [newOptions])

  const [errorMsg, setErrorMsg] = useState(false);
  useEffect(() => {
    if ((value === undefined || value === "") && required) {
      setErrorMsg(true);
    } else {
      setErrorMsg(false);
    }
    setSelectedOptions(value);
  }, [value]);

  const getSelectedOptionName = () => {
    let allOptions = [];
    if (newOptions.length > 0) {
      newOptions.forEach((option) => {
        if (selectedOptions.includes(option.id)) {
          allOptions.push(option.name);
        }
      });
    } else {
      allOptions = options.filter((option) => {
        if (selectedOptions.includes(option)) {
          return option;
        }
        return false;
      });
    }

    return allOptions.join(",");
  };
  const maxOptionLengthAllowed = 20

  return (
    <div className={"form-group my-2 " + upperClass}>
      {from !== "Table" && label && (
        <label htmlFor={name} style={{ width: `${18}%` }}>
          {label}
          <span className="required_mark"> {required ? ` *` : null}</span>
        </label>
      )}
      {multiple ? (
        <Dropdown
          className={
            "w-" + (fieldWidth ?? "75") + " d-inline-block mx-2 " + specialClass
          }
        >
          <Dropdown.Toggle id="dropdown-basic">
            <span className="multipleSelectHeader">
              {selectedOptions.length === 0
                ? "Select " + (label || "")
                : getSelectedOptionName()}
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {newOptions.length > 0
              ? newOptions.map((option, index) => (
                <Dropdown.Item
                  key={"Dropdown-" + index}
                  onClick={(e) => toggleOption(e, option.id)}
                  active={selectedOptions.includes(option.id)}
                >
                  <input
                    type="checkbox"
                    onChange={(e) => toggleOption(e, option.id)}
                    checked={selectedOptions.includes(option.id)}
                  />
                  {option.name}
                </Dropdown.Item>
              ))
              : options.map((option, index) => (
                <Dropdown.Item
                  key={"Dropdown-" + index}
                  onClick={(e) => toggleOption(e, option)}
                  active={selectedOptions.includes(option)}
                >
                  <input
                    type="checkbox"
                    onChange={(e) => toggleOption(e, option)}
                    checked={selectedOptions.includes(option)}
                    value={option}
                  />
                  {option}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <div className={"w-" + (fieldWidth ?? "75") + " d-inline-block mx-2"}>
          <select
            id={name || label}
            name={name || label}
            value={value}
            required={required}
            className="form-control rounded-2"
            multiple={multiple}
            onChange={onChange}
            onBlur={handleFieldBlur || null}
            disabled={viewOnly || readOnly}
          >

            <option value="" disabled className="optionDisable">
              {placeholder ?? "Select " + label}
            </option>

            {newOptions.length > 0
              ? newOptions?.map((option, optionIndex) => (
                <option key={"optionIndex" + optionIndex} value={option?.id} title={option?.name}>
                  {option?.name.length > maxOptionLengthAllowed ? option?.name.slice(0, maxOptionLengthAllowed) + '...' : option?.name}
                </option>
              ))
              : options?.map((option, optionIndex) => (
                <option key={"optionIndex" + optionIndex} value={option} title={option}>
                  {option.length > maxOptionLengthAllowed ? option.slice(0, maxOptionLengthAllowed) + '...' : option}

                </option>
              ))}

          </select>
          {errorMsg && actionClicked ? (
            <p className="text-danger errorMsg">{label} is Required</p>
          ) : null}
          {error && actionClicked && <p className="text-danger">{error}</p>}
        </div>
      )}
    </div>
  );
};

InputSelect.propTypes = {
  field: PropTypes.object.isRequired,
};

export default InputSelect;
