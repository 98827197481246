import React, { useState, useEffect } from "react";
import { postDataFromApi } from "../../../services/commonServices";
import { useParams } from "react-router-dom";
import Loading from "../Loading";


const Preview = () => {
    const [pdfUrl, setPdfUrl] = useState(null);
    let { EditRecordId } = useParams();

    const handleCreateCertificatePrint = async () => {
        console.log("EditRecordId : ", EditRecordId);
        let payload = {
            "ji_id": EditRecordId,
            "context": "scope"
        };
        let res = await postDataFromApi("/comercial-certificate-pdf/", payload, "", true, "", "");

        if (res && res.data) {
            // Create a URL for the PDF Blob data
            const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(pdfBlob);
            setPdfUrl(pdfUrl); // Set the PDF URL in state to display in the iframe
        }
    };

    // Call the function to fetch the PDF when the component mounts
    useEffect(() => {
        handleCreateCertificatePrint();
    }, []);

    return (
        <div style={{ position: "relative" }} className={"form-group my-2 previewCommercialCertificate"}>
            <h1 className="previewCommercialCertificateHeading">Certificate</h1>
            <div className={"w-100 d-inline-block mx-2"}>
                {pdfUrl ? (
                    <iframe
                        src={`${pdfUrl}#view=FitH&navpanes=0&toolbar=0&scrollbar=0`}  // Hides sidebar, header, toolbar, and scrollbars
                        title="Custom PDF Preview"
                        name="custom_pdf_iframe"  // Custom name for the iframe
                        width="100%"
                        height="1120px"
                        style={{ border: "none", backgroundColor: "white" }}  // Set the iframe background to white
                    />

                ) : (
                    <Loading />
                )}
            </div>
        </div>
    );
};

export default Preview;
