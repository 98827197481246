import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const InputText = ({ field }) => {
  const {
    name,
    label,
    value,
    onChange,
    required,
    error,
    placeholder,
    readOnly,
    tooltip,
    validation,
    icon,
    fieldWidth,
    pattern,
    actionClicked,
    viewOnly,
  } = field;

  const [errorMsg, setErrorMsg] = useState(false);
  useEffect(() => {
    const regex = new RegExp(pattern);
    if (value !== "") {
      if (regex.test(value)) {
        setErrorMsg(false);
      } else {
        setErrorMsg(true);
      }
    } else {
      setErrorMsg(false);
    }
  }, [value]);

  useEffect(() => {
    if ((value === undefined || value === "") && required) {
      setErrorMsg(true);
    } else {
      setErrorMsg(false);
    }
  }, [value]);

  return (
    <div className="form-group my-2" style={{ position: "relative" }}>
      {label && (
        <label htmlFor={name} style={{ width: `${18}%` }}>
          {label}
          <span className="required_mark"> {required ? ` *` : null}</span>
        </label>
      )}
      <div className={"w-" + (fieldWidth ?? "75") + " d-inline-block mx-2"}>
        <textarea
          id={name || label}
          name={name || label}
          value={value}
          onChange={onChange}
          required={required}
          placeholder={placeholder}
          className="form-control rounded-2"
          readOnly={readOnly}
          title={tooltip}
          pattern={validation?.pattern}
          disabled={viewOnly}
        />

        {icon ? (
          <i className={"bi bi-" + icon + " text-danger text-bold"}></i>
        ) : (
          <span></span>
        )}

        {errorMsg && actionClicked ? (
          <p className="text-danger errorMsg">{label} is Required</p>
        ) : null}

        {error && actionClicked && <p className="text-danger">{error}</p>}
      </div>
    </div>
  );
};

InputText.propTypes = {
  field: PropTypes.object.isRequired,
};
export default InputText;
