import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import React from "react";
import PropTypes from "prop-types";
import {
  handleJIUpdateStatus,
  handleJIValidation,
} from "../../commonHandlerFunction/jobinstructionHandlerFunctions";
import { vesselListBackFunctionality } from "../../commonHandlerFunction/operations/TMLOperations";
const JIButtons = ({
  action,
  tabOpen,
  setIsPopupOpen,
  setJRFCreationType,
  setInwardBtnchange,
  formData,
  subTableData,
  EditRecordId,
  viewOnly,
  handleBackButtonFunction,
  navigate,
  editReordType,
  setJrfCreationType,
  handleSubmit,
  formConfig,
  setIsOverlayLoader,
  setIsRejectPopupOpen,
  useForComponent,
  activeTab,
  setActiveTab,
  testMemoSetData,
  isDisplayNewAddOption,
  isViewOnlyTable,
  operationStepNo,
  OperationTypeID,
  OperationType,
  parameterDataTableMain,
}) => {
  const { t } = useTranslation();
  const translate = t;
  const getTotalNotFilledCount = () => {
    let count = 0;
    testMemoSetData.forEach((tab, tabIndex) => {
      count += formData["tab_" + tabIndex]?.["noFilledCount"];
    });
    return count;
  };
  const checkValidation = () => {
    if (OperationType === "H&H") {
      return subTableData.length === 0;
    } else if (
      [
        "Pre-Shipment (PSI)",
        "Quality Analysis",
        "Transportable moisture limit (TML)",
      ].includes(OperationType)
    ) {
      if (operationStepNo == 1) {
        const paramsaved = parameterDataTableMain.filter((singleParam) => {
          return singleParam.labIdSaved;
        });
        return paramsaved.length === 0;
      } else if (operationStepNo == 2) {
        return subTableData.length == 0;
      } else if (operationStepNo == 3) {
        console.log("subTableData", subTableData);
        return subTableData.length == 0;
      }
    }
    return false;
  };
  // checkValidation();
  return (
    <div className="submit_btns">
      {!useForComponent ? (
        !viewOnly && (
          <React.Fragment>
            <Button
              type="button"
              className="cancelBtn"
              id="submit_btn3"
              onClick={() => {
                handleBackButtonFunction();
              }}
            >
              {translate("common.backBtn")}
            </Button>
            
            <>
              <button
                type="button"
                className="saveBtn"
                id="submit_btn2"
                data-name="save"
                onClick={(e) =>
                  handleJIValidation(
                    handleSubmit,
                    setJrfCreationType,
                    setIsPopupOpen,
                    "save"
                  )
                }
              >
                {translate("common.saveBtn")}
              </button>
              <Button
                type="button"
                className="submitBtn"
                id="submit_btn1"
                disabled={editReordType !== "nomination"}
                onClick={(e) =>
                  handleJIValidation(
                    handleSubmit,
                    setJrfCreationType,
                    setIsPopupOpen,
                    "post"
                  )
                }
              >
                {translate("common.postBtn")}
              </Button>
              
              {editReordType !== "nomination" && (
                <Button
                  type="button"
                  className="submitBtn"
                  id="submit_btn1"
                  onClick={(e) =>
                    handleJIUpdateStatus(
                      formData,
                      formConfig,
                      setIsOverlayLoader,
                      editReordType,
                      navigate,
                      "",
                      "",
                      "",
                      subTableData
                    )
                  }
                  disabled={
                    editReordType !== "nomination" && subTableData.length === 0
                  }
                >
                  {/* {!editReordType ? "Scope of Work" : "Parameters"} */}
                  Next
                </Button>
              )}
            </>
          </React.Fragment>
        )
      ) : useForComponent === "OperationDetails" ? (
        <React.Fragment>
          <Button
            type="button"
            className="cancelBtn"
            id="submit_btn3"
            onClick={() => {
              navigate("/operation/vessel-ji-list/vessel-list/" + EditRecordId);
            }}
          >
            Cancel
          </Button>
          <Button
            type="button"
            className="cancelBtn"
            id="submit_btn3"
            onClick={
              ["Supervision"].includes(OperationType) && activeTab !== "1-0"
                ? () => {
                    const spValue = activeTab.split("-");
                    const newValue = parseInt(spValue[1]) - 1;
                    console.log("spValue", spValue, newValue);
                    setActiveTab("1-" + newValue);
                  }
                : () => {
                    vesselListBackFunctionality(
                      formData,
                      OperationType,
                      OperationTypeID,
                      navigate,
                      operationStepNo
                    );
                  }
            }
          >
            {translate("common.backBtn")}
          </Button>

          {!isViewOnlyTable ? (
            <>
              {" "}
              <button
                type="button"
                className="saveBtn"
                id="submit_btn2"
                data-name="save"
                onClick={(e) => {
                  setJRFCreationType("save");
                  setIsPopupOpen(true);
                }}
                disabled={checkValidation()}
              >
                {![
                  "Pre-Shipment (PSI)",
                  "Quality Analysis",
                  "Transportable moisture limit (TML)",
                ].includes(OperationType)
                  ? "Save"
                  : "Next"}
              </button>
              {["Supervision"].includes(OperationType) &&
                (activeTab !== "1-4" ? (
                  <Button
                    type="button"
                    className="submitBtn"
                    id="submit_btn1"
                    onClick={(e) => {
                      const spValue = activeTab.split("-");
                      const newValue = parseInt(spValue[1]) + 1;
                      console.log("spValue", spValue, newValue);
                      setActiveTab("1-" + newValue);
                    }}
                  >
                    Next
                  </Button>
                ) : (
                  <Button
                    type="button"
                    className="submitBtn"
                    id="submit_btn1"
                    onClick={(e) => {
                      setJRFCreationType("post");
                      setIsPopupOpen(true);
                    }}
                  >
                    Post
                  </Button>
                ))}
              {![
                "Pre-Shipment (PSI)",
                "Quality Analysis",
                "Transportable moisture limit (TML)",
                "Supervision",
              ].includes(OperationType) && (
                <Button
                  type="button"
                  className="submitBtn"
                  id="submit_btn1"
                  disabled={checkValidation()}
                  onClick={(e) => {
                    setJRFCreationType("post");
                    setIsPopupOpen(true);
                  }}
                >
                  Post
                </Button>
              )}
            </>
          ) : (
            [
              "Pre-Shipment (PSI)",
              "Quality Analysis",
              "Transportable moisture limit (TML)",
            ].includes(OperationType) && (
              <>
                {/* <button
                  type="button"
                  className="saveBtn"
                  id="submit_btn2"
                  data-name="save"
                  onClick={(e) => {
                    
                  }}
                >
                  Next
                </button> */}
              </>
            )
          )}
        </React.Fragment>
      ) : useForComponent === "OperationDetailsAssignment" ? (
        <React.Fragment>
          <Button
            type="button"
            className="cancelBtn"
            id="submit_btn3"
            onClick={() => {
              vesselListBackFunctionality(
                formData,
                OperationType,
                OperationTypeID,
                navigate,
                operationStepNo
              );
            }}
          >
            {translate("common.backBtn")}
          </Button>
          
          {operationStepNo != 5 && (
            <button
              type="button"
              className="saveBtn"
              id="submit_btn2"
              data-name="save"
              disabled={checkValidation()}
              onClick={(e) => {
                setJRFCreationType("post");
                setIsPopupOpen(true);
              }}
            >
              Next
            </button>
          )}
          {operationStepNo == 5 && (
            <>
              <Button
                type="button"
                className="submitBtn"
                id="submit_btn1"
                onClick={(e) => {
                  setJRFCreationType("post");
                  setIsPopupOpen(true);
                }}
              >
                Final Post
              </Button>
              <button
                type="button"
                className="submitBtn"
                id="submit_btn1"
                onClick={(e) => {
                  setJRFCreationType("postJRF");
                  setIsPopupOpen(true);
                }}
              >
                Send to JRF
              </button>
              <button
                type="button"
                className="submitBtn"
                id="submit_btn1"
                onClick={(e) => {
                  setJRFCreationType("postOther");
                  setIsPopupOpen(true);
                }}
              >
                Send to Other TPI
              </button>
            </>
          )}
        </React.Fragment>
      ) : useForComponent !== "OperationsList" ? (
        <React.Fragment>
          <Button
            type="button"
            className="cancelBtn"
            id="submit_btn3"
            disabled={activeTab === "1-0"}
            onClick={(e) => {
              const spValue = activeTab.split("-");
              const newValue = parseInt(spValue[1]) - 1;
              console.log("spValue", spValue, newValue);
              setActiveTab("1-" + newValue);
            }}
          >
            {translate("common.backBtn")}
          </Button>
          
          <>
            {useForComponent === "OperationsJIDetails" ? (
              <>
                {activeTab !== "1-2" ? (
                  <Button
                    type="button"
                    className="submitBtn"
                    id="submit_btn1"
                    onClick={(e) => {
                      const spValue = activeTab.split("-");
                      const newValue = parseInt(spValue[1]) + 1;
                      console.log("spValue", spValue, newValue);
                      setActiveTab("1-" + newValue);
                    }}
                  >
                    Next
                  </Button>
                ) : (
                  !isViewOnlyTable && (
                    <>
                      <Button
                        type="button"
                        className="rejectBtn"
                        id="submit_btn1"
                        onClick={(e) => setIsRejectPopupOpen(true)}
                      >
                        {translate("common.rejectBtn")}
                      </Button>
                      <Button
                        type="button"
                        className="submitBtn"
                        id="submit_btn1"
                        onClick={(e) =>
                          handleJIUpdateStatus(
                            formData,
                            formConfig,
                            setIsOverlayLoader,
                            editReordType,
                            navigate,
                            1,
                            "accepted",
                            "",
                            subTableData
                          )
                        }
                      >
                        {translate("common.acceptBtn")}
                      </Button>
                    </>
                  )
                )}
              </>
            ) : useForComponent === "OperationDetailsOtherTPI" ? (
              !viewOnly &&
              !getTotalNotFilledCount() && (
                <>
                  <Button type="button" className="submitBtn" id="submit_btn1">
                    {translate("common.postBtn")}
                  </Button>
                </>
              )
            ) : null}
          </>
        </React.Fragment>
      ) : null}
    </div>
  );
};

JIButtons.propTypes = {
  action: PropTypes.func, // Function for handling actions
  tabOpen: PropTypes.bool, // Boolean to indicate if a tab is open
  setIsPopupOpen: PropTypes.func, // Function to set popup open state
  setJRFCreationType: PropTypes.func, // Function to set JRF creation type
  setInwardBtnchange: PropTypes.func, // Function to handle inward button changes
  formData: PropTypes.object, // Form data, object type
  subTableData: PropTypes.array, // Array of sub-table data
  EditRecordId: PropTypes.any, // ID of the record being edited; use 'any' or specify type if known
  viewOnly: PropTypes.bool, // Boolean to indicate view-only mode
  handleBackButtonFunction: PropTypes.func, // Function for handling back button
  navigate: PropTypes.func, // Function for navigation
  editReordType: PropTypes.string, // Type of record being edited, string type
  setJrfCreationType: PropTypes.func, // Function to set JRF creation type
  handleSubmit: PropTypes.func, // Function to handle form submission
  formConfig: PropTypes.object, // Configuration for the form
  setIsOverlayLoader: PropTypes.func, // Function to set overlay loader state
  setIsRejectPopupOpen: PropTypes.func, // Function to set reject popup open state
  useForComponent: PropTypes.string, // String to specify use for component
  activeTab: PropTypes.string, // String to indicate the active tab
  setActiveTab: PropTypes.func, // Function to set active tab
  testMemoSetData: PropTypes.object, // Object containing test memo data
  isDisplayNewAddOption: PropTypes.bool, // Boolean to control display of new add option
};

export default JIButtons;
