import React, {  useState } from "react";
import { Row, Col } from "reactstrap";

import formConfig from "../../formJsonData/Operations/jobinstructions/JrfInstruction_analys.json";
import Form from "../../components/common/Form";

const JfInstructionAnalys = () => {
  const [masterResponse, setMasterResponse] = useState([]);
  const tileHeader = [{ Text: "Company & Commodity", leftSubTitle: "Step 1", rightSubTitle: "In Progress" },
    { Text: "Scope of Work", leftSubTitle: "Step 2", rightSubTitle: "In Progress" },
    { Text: "Groups & Parameters", leftSubTitle: "Step 3", rightSubTitle: "In Progress" },
    { Text: "Nominations Details", leftSubTitle: "Step 4", rightSubTitle: "In Progress" }
    ]

  return (
    <Row className="rowWidthAdjust">
      <Col>
        <Form formConfig={formConfig} masterResponse={masterResponse} setMasterResponse={setMasterResponse} tileHeader={tileHeader}/>
      </Col>
    </Row>
  );
};

export default JfInstructionAnalys;
