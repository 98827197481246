import React, { useEffect, useRef, useState } from "react";
import PopupOptions from "./popupOptions";
import {
  getComonCodeForCompany,
  getFormatedDate,
} from "../../services/commonFunction";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { postDataFromApi, putDataFromApi } from "../../services/commonServices";
import { getJIsowandactivityApi, getReportConfigApi, getCommercialCertificateListApi } from "../../services/api";
import { encryptDataForURL } from "../../utills/useCryptoUtils";
import PropTypes from "prop-types";
import { getCellData } from "../../services/commonFunction";
import { toast } from "react-toastify";
import { getConfig } from "@testing-library/react";

export const selectUser = (state) => state.user;

const RenderSubListSection = ({
  section,
  sectionIndex,
  actions,
  responseData,
  // getAllSubListingdata,
  formConfig,
  statusCounts,
  setIsRejectPopupOpen,
  setJRFCreationType,
  setIsPopupOpen,
  loadingTable,
  setIsOverlayLoader,
  moduleType,
  formData,
  breadcrumb
}) => {
  let user = useSelector(selectUser);
  const navigate = useNavigate();
  const session = useSelector((state) => state.session);

  user = session.user;
  console.log(user);

  const [popupIndex, setPopupIndex] = useState(-1);
  const [sortStates, setSortStates] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [sizeOfPage, setSizeOfPage] = useState(0);
  const [countPage, setCountPage] = useState(0);
  const [filteredAction, setFilteredAction] = useState(actions);
  const [subTableData, setSubTableData] = useState([]);

  const popupRef = useRef(null);
  useEffect(() => {
    setSortStates(Array(section.headers?.length).fill(false));
  }, [section]);
  let { EditRecordId, TMLType } = useParams();

  useEffect(() => {
    //  if (moduleType == "confirugationList") {
    //       getConfigListData(EditRecordId, TMLType);
    //     }
    //     else if (moduleType == "commercialCertificateList") {
    //       getCommercialCertificateList();
    //       //getCommercialCertificateList
    //     }
    getAllSubListingdata();

  }, []);

  const getConfigListData = async (ji_id, jis_id) => {
    try {
      const bodyData = {
        "ji_id": ji_id,
        "jis_id": jis_id
      }
      let res = await postDataFromApi(getReportConfigApi, bodyData);
      if (res?.data?.status === 200 && res.data.data) {
        const responseData = res.data.data;
        setSubTableData(responseData);
        setCurrentPage(res.data.current_page);
        setTotalPage(res.data.total_pages);
        setSizeOfPage(res.data.page_size);
        setCountPage(res.data.count);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getCommercialCertificateList = async () => {
    try {
      let res = await postDataFromApi(getCommercialCertificateListApi);
      if (res?.data?.status === 200 && res.data.data) {
        const responseData = res.data.data;
        setSubTableData(responseData);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleClick = (index, fieldName) => {
    const newSortStates = Array(section.headers?.length).fill(false);
    newSortStates[index] = !sortStates[index];
    let sortType = newSortStates[index] ? "desc" : "asc";
    getAllSubListingdata(currentPage, fieldName, sortType);
    setSortStates(newSortStates);
  };


  const statusesWithColor = formConfig?.listView?.statusesWithColor;


  const getStatus = (formConfig, row) => {
    switch (moduleType) {
      case "sampleinward":
        return row["smpl_status"];
      case "testmemomain":
      case "allotment":
      case "sampleverification":
        return row["status"];
      case "sfm":
        return row["sfm_status"];
      case "internalcertificate":
        return row["status"];
      case "jobinstruction":
        return row["ji_internal_status"];
      case "vesselOperation":
        return row["status"];
      case "confirugationList":
        return row["status"]
      default:
        return row["jrf_status"];
    }
  };



  const getStatusNameValue = (cellData) => {
    let statusData = statusesWithColor;

    let filterStatusData = statusData.filter((jrfColor, jrfColorIndex) => {
      if (Array.isArray(jrfColor.status)) {
        return jrfColor.status.includes(cellData);
      } else {
        return (
          jrfColor &&
          cellData &&
          jrfColor.status.toLowerCase() == cellData.toLowerCase()
        );
      }
    });
    if (filterStatusData.length > 0) {
      filterStatusData = filterStatusData[0];
      return (
        <td key="status_list" className="status-td">
          <div
            className={"table_item_sym " + filterStatusData?.icon + "_bg"}
            key={"table-item"}
          >
            {/* <div className={filterStatusData?.icon}> </div> */}
            {filterStatusData?.name}
          </div>
        </td>
      );
    } else {
      return (
        <td key="status_list">
          <div className="table_item_sym" key={"table-item"} title="">

            {cellData}
          </div>
        </td>
      );
    }
  };

  const getBooleanName = (cellData) => {

    return (
      <td key="status_list">
        <div className="table_item_sym" key={"table-item"} title="">

          {cellData ? "Yes" : "No"}
        </div>
      </td>
    );

  };


  const getAllSubListingdata = async (
    pagination = "",
    fieldName = "",
    sortType = "",
    searchValue = "",
    isClearBtn = "",
  ) => {
    try {
      // let endPoint =
      //   ["LC", "SLC"].includes(user?.role) && listModuleType === "allotment"
      //     ? formConfig?.apiEndpoints?.readUserWise
      //     : formConfig?.apiEndpoints?.read;
      // if (!endPoint) {
      //   return;
      // }
      let querystring = "";
      if (pagination) {
        querystring += "?page=" + pagination;
      }


      // const res = await postDataFromApi(endPoint + querystring);
      let res
      if (moduleType == "confirugationList") {
        // getConfigListData(EditRecordId, TMLType);
        const bodyData = {
          "ji_id": EditRecordId,
          "jis_id": TMLType
        }
        res = await postDataFromApi(getReportConfigApi + querystring, bodyData);
      }
      else if (moduleType == "commercialCertificateList") {
        // getCommercialCertificateList();
        res = await postDataFromApi(getCommercialCertificateListApi + querystring);
        //getCommercialCertificateList
      }
      if (res?.data?.status === 200) {
        setSubTableData(res.data.data);
        setCurrentPage(res.data.current_page);
        setTotalPage(res.data.total_pages);
        setSizeOfPage(res.data.page_size);
        setCountPage(res.data.count);
        // setLoadingTable(false);
      } else {
        toast.error(res.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      // setLoadingTable(false);

      toast.error(error.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      // setLoadingTable(false);
    }
  };

  const handlePrev = () => {
    getAllSubListingdata(currentPage - 1);
    setCurrentPage((prev) => prev - 1);
  };
  const handleNext = () => {
    getAllSubListingdata(currentPage + 1);
    setCurrentPage((prev) => prev + 1);
  };

  const handlePaginationButton = (pageNo) => {
    getAllSubListingdata(pageNo);
    setCurrentPage(pageNo);
  };

  const createButton = (page, currentPage, handlePaginationButton) => {
    return (
      <button
        type="button"
        className={
          currentPage === page
            ? "btn btn-danger pagination-active"
            : "btn btn-danger"
        }
        key={"page-" + page}
        onClick={() => handlePaginationButton(page)}
      >
        {page}
      </button>
    );
  };

  const getPaginationNo = () => {
    const displayedPages = [];
    const maxPagesToShow = 7; // Limit visible page buttons to avoid clutter.

    const middle = Math.floor(maxPagesToShow / 2);
    const left = Math.max(1, currentPage - middle);
    const right = Math.min(totalPage, currentPage + middle);

    if (left > 1) {
      displayedPages.push(createButton(1, currentPage, handlePaginationButton));
      if (left > 2) {
        displayedPages.push(<button key={"extra-eclipse"}>...</button>);
      }
    }

    for (let i = Math.max(1, left); i <= Math.min(totalPage, right); i++) {
      displayedPages.push(createButton(i, currentPage, handlePaginationButton));
    }

    if (right < totalPage) {
      if (right < totalPage - 1) {
        displayedPages.push(<button key={"extra-display-btn"}>...</button>);
      }
      displayedPages.push(
        createButton(totalPage, currentPage, handlePaginationButton)
      );
    }

    return displayedPages;
  };

  const divRef = useRef(null);
  /*
  Author : Yash Darshankar
  Date : 20/06/2024
  Description : This code is used to close the popup when clicked outside the popup.
  */
  const popupOptionsRef = useRef(null);
  const [isBottom, setIsBottom] = useState(false);
  const pageLimit =
    currentPage === totalPage
      ? Math.floor(countPage % sizeOfPage) > 3
        ? Math.floor(countPage % sizeOfPage)
        : sizeOfPage
      : sizeOfPage;
  useEffect(() => {
    if (pageLimit - 2 === popupIndex || pageLimit - 1 === popupIndex) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  }, [popupIndex]);

  const popupIntentionallyOpenedRef = useRef(false);
  /*
   */
  const [dontClick, setDontClick] = useState(false);
  useEffect(() => {
    const handler = (event) => {
      const isLastTd = event.target.classList.contains("last-td");
      const isInsidePopup =
        popupOptionsRef.current &&
        popupOptionsRef.current.contains(event.target);
      if (
        !isInsidePopup &&
        !popupIntentionallyOpenedRef.current &&
        !isLastTd &&
        !dontClick
      ) {
        setPopupIndex(-1);
      }
      popupIntentionallyOpenedRef.current = false;
    };

    document.addEventListener("click", handler);
    setDontClick(false);
    return () => document.removeEventListener("click", handler);
  }, [dontClick]);

  const checkIsShow = (fieldName) => {
    if (moduleType === "allotment") {
      if (
        fieldName === "inward_detail" ||
        fieldName === "sample_allotedto_data"
      ) {
        if (user?.role === "LC") {
          return false;
        }
      }
    } else if (moduleType === "jrf") {
      if (fieldName === "jrf_lab_detail") {
        if (user?.role === "LR") {
          return false;
        }
      } else if (fieldName === "jrf_branch_detail") {
        if (user?.role === "BU") {
          return false;
        }
      }
    }
    return true;
  };

  const handleOnClick = (row) => {
    navigate(
      "/operation/vessel-ji-list/vessel-list/" +
      encryptDataForURL(formData[0]?.["ji_id"]) +
      "/" +
      encryptDataForURL(row["activity_master"]["activity_name"]) +
      "?OperationType=" +
      encryptDataForURL(row["activity_master"]["activity_name"]) +
      "&operationId=" +
      encryptDataForURL(row["jis_id"]) +
      "&operationStepNo=" +
      encryptDataForURL(1)
    );


  };
  const handleCertificate = (row) => {
    navigate(
      "/operation/vessel-ji-list/vessel-list/confirugation-certificate-list/" +
      encryptDataForURL(formData[0]?.["ji_id"]) +
      "/" +
      encryptDataForURL(row["jis_id"])
    );
  };

  const handleCreateConfirugation = (status, RPCID) => {
    console.log("Redirecting to creating configuration ")
    // /operation/operation-certificate/44
    if (RPCID) {
      navigate("/operation/operation-certificate/" + EditRecordId + "/" + TMLType + "?status=" + status + "&RPCID=" + RPCID);

    }
    else {
      navigate("/operation/operation-certificate/" + EditRecordId + "/" + TMLType + "?status=" + status);

    }
  }
  const handleGenerateConfirugation = (row) => {
    console.log(row);
    // navigate("/operation/vessel-ji-list/vessel-list/commercial-certificate-preview/" + EditRecordId);
    navigate("/operation/vessel-ji-list/vessel-list/confirugation-certificate/" + row?.fk_jiid + "/" + row?.fk_jisid + "/" + row?.rpc_id)


  }
  const handleDownloadCertificate = (row) => {
    console.log(row);
    // navigate("/operation/vessel-ji-list/vessel-list/commercial-certificate-preview/" + EditRecordId);
    navigate("/operation/vessel-ji-list/vessel-list/commercial-certificate-preview/" + row?.fk_jiid)


  }

  const ApproveCertificate = async (row) => {

    navigate(`/operation/vessel-ji-list/vessel-list/confirugation-certificate/${row.fk_jiid}/${row.fk_jisid}/${row.cc_id}?status=approve`);
  }

  const handlePublish = async (row) => {
    console.log("row : ", row);
    let payload = {
      "data": {
        "fd_name": row?.cc_refencenumber || row?.cc_certificatenumber
      },
      "parent_folder": "commercial_certificate"
    }
    let folderResponse = await postDataFromApi("/folder/create/", payload);
    if (folderResponse.status === 201) {
      console.log("folderResponse : ", folderResponse);
      let payload = {
        "ji_id": row?.fk_jiid,
        "context": "scope"
      };
      let generateCertificateResponse = await postDataFromApi("/comercial-certificate-pdf/", payload, "", true, "", "");

      if (generateCertificateResponse && generateCertificateResponse.data && generateCertificateResponse.status === 200) {
        // Assuming generateCertificateResponse.data contains base64 image data
        // const base64Image = generateCertificateResponse.data; // Replace this with your actual image data

        // Convert base64 string to Blob
        // const response = await fetch(base64Image);
        // const imageBlob = await response.blob(); // Create a Blob from the base64 image

        // // Get the size of the blob in bytes
        // const imageSizeInBytes = imageBlob.size;
        // console.log(`Image Size: ${imageSizeInBytes} bytes`);

        // // Optionally, convert the size to kilobytes (KB) or megabytes (MB)
        // const imageSizeInKB = (imageSizeInBytes / 1024).toFixed(2); // KB
        // const imageSizeInMB = (imageSizeInBytes / (1024 * 1024)).toFixed(2); // MB

        // console.log(`Image Size: ${imageSizeInKB} KB`);
        // console.log(`Image Size: ${imageSizeInMB} MB`);

        // Append the image Blob to FormData
        const pdfBlob = new Blob([generateCertificateResponse.data], { type: 'application/pdf' });
        let payload = new FormData();

        // Appending the PDF Blob as a file, specifying a file name like 'certificate.pdf'
        payload.append('document', pdfBlob, 'certificate.pdf');

        // Now you can upload it just like a file
        let uploadResponse = await postDataFromApi("/masters/upload/", payload, "TRUE");




        if (uploadResponse.status === 200) {
          // alert("Upload successfully!");
          console.log("uploadResponse : ", uploadResponse);
          let payload = {
            "data":
            {
              "dl_folder": folderResponse?.data?.data?.fd_id,
              "dl_sub_folder": 6,
              "dl_module": "Module Named ",
              "dl_document_name": row?.cc_refencenumber || row?.cc_certificatenumber,
              "dl_document_reference": row?.fk_jiid,
              "dl_type": "Document Type",
              "dl_show_to_all": true,
              "dl_s3_url": uploadResponse.data?.data?.document,
              "dl_version": "1.0",
              "dl_date_uploaded": "2023-10-23T12:00:00Z",
              "dl_status": "Active",
              "dl_assigned_to": "Assigned User",
              "dl_discription": row?.cc_refencenumber || row?.cc_certificatenumber,
            }

          }
          let documentResponse = postDataFromApi("/documents/create/", payload);
          if (documentResponse.status === 201) {
            PublishCertificate(row.cc_id);
          }
        } else {
          alert("Error in Uploading file");
        }
      }


    }
  }
  const PublishCertificate = async (CCID) => {
    let payload = {
      "cc_id": CCID,
      "commercial_certificate": {
        "status": "published"
      }
    }
    let res = await putDataFromApi("/commercial-cert/update/", payload);
    if (res.status === 200) {
      // navigate("/operation/commercial-certificate-list");
    }
  }


  const handleDownloadCertificateAfterPublish = async (row) => {
    let payload = {
      "ji_id": row?.fk_jiid,
      "context": "scope"
    };
    let generateCertificateResponse = await postDataFromApi("/comercial-certificate-pdf/", payload, "", true, "", "");
    if (generateCertificateResponse && generateCertificateResponse.data) {
      const pdfBlob = new Blob([generateCertificateResponse.data], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Create a link element
      const link = document.createElement('a');
      link.href = pdfUrl;

      // Set the filename for the download
      link.download = 'certificate.pdf';

      // Programmatically click the link to trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up by removing the link and revoking the object URL
      document.body.removeChild(link);
      URL.revokeObjectURL(pdfUrl);
    }

  }

  const getConfigTypeCellContent = (row, index) => {
    let cellContent = "--"; // Default content

    if (row["is_consortium"] && row["is_dual_port"]) {
      cellContent = "Consortium, Dual Port";
    } else if (row["is_consortium"]) {
      cellContent = "Consortium";
    } else if (row["is_dual_port"]) {
      cellContent = "Dual Port";
    }

    return <td key={`cellIndex${index}`}>{cellContent}</td>;
  };


  return (
    <div key={sectionIndex} className="row my-2 mx-0 renderList_header_table">

      <div className="renderList_table_container">
        {" "}
        <div className="renderList_table" ref={divRef}>
          <div className="renderList_table_heading">
            <h2>
              {breadcrumb[breadcrumb.length - 1].title}
            </h2>
            {moduleType == "confirugationList" &&
              <button type="button" className="submitBtn CreateConfirugationBtn" onClick={() => handleCreateConfirugation("saved")}>
                + Create Confirugation
              </button>}
          </div>
          <table className="table table-white responsive borderless no-wrap align-middle list configureList">
            <thead>
              <tr className="border-top">
                <th>Sr. No.</th>
                {section.headers?.map(
                  (header, headerIndex) =>
                    checkIsShow(header.name) && (
                      <th
                        key={"HeaderIndex -" + headerIndex}
                        colSpan={header.colSpan ?? 1}
                        onClick={() =>
                          handleClick(headerIndex, header?.sortName)
                        }
                      >
                        {header?.label}
                        {/* <span className="table_header_icon">
                          {sortStates[headerIndex] ? (
                            <i className="bi bi-caret-up-fill"></i>
                          ) : (
                            <i className="bi bi-caret-down-fill"></i>
                          )}
                        </span> */}
                      </th>
                    )
                )}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {subTableData?.map((row, rowIndex) => (
                <tr
                  key={"rowIndex-" + rowIndex}
                  // className={getTdBorderColor(row)}
                  className="border-top"
                >
                  <td key={"rowIndex-" + rowIndex + "1"} >{rowIndex + 1}</td>
                  {section.headers?.map((header, index) => {


                    if (!checkIsShow(header.name)) {
                      return null;
                    }
                    let cellData = formData[0]?.[header?.name];
                    if (!cellData) {
                      cellData = row[header?.name];
                    }
                    if (header?.fieldName === "status") {
                      return getStatusNameValue(row[header?.name]);
                    }
                    else if (header?.fieldName === "rpc_is_smpl_wghtorunit" || header?.fieldName === "rpc_is_smpl_type") {
                      return getBooleanName(row[header?.name]);
                    }
                    else if (header?.fieldName === "config_type") {
                      return getConfigTypeCellContent(row, index);
                    }

                    else if (header?.fromType === "array") {
                      return (
                        <td key={"cellIndex" + index}>
                          {cellData
                            ? header.type === "date"
                              ? getFormatedDate(cellData[header?.fieldName], 1)
                              : header.fieldName === "company_code" ? getComonCodeForCompany(cellData[header?.fieldName]) : getCellData(cellData[header?.fieldName])
                            : "--"}
                        </td>
                      );
                    } else {
                      return (
                        <td key={"cellIndex" + index} title={cellData}>
                          {header.type === "date"
                            ? getFormatedDate(cellData, 1)
                            : getCellData(cellData)}
                        </td>
                      );
                    }
                  })}

                  <td className="exclude-click last-td" ref={popupRef}>
                    {

                      moduleType === "commercialCertificateList" ?
                        <div className="actionColumn maxWidth d-flex confirugationListActionColumn">
                          {(row['status'] === "saved" && user?.logged_in_user_info?.role === "BH") && <button
                            type="button"
                            className="iconBtn"
                            onClick={() => ApproveCertificate(row)}
                          >Approve Certificate
                          </button>}
                          {(row['status'] === "approved" && user?.logged_in_user_info?.role === "BU") && <button
                            type="button"
                            className="iconBtn"
                            onClick={() => handlePublish(row)}
                          >Publish
                          </button>}
                          {(row['status'] === "published" && user?.logged_in_user_info?.role === "BU") && <button
                            type="button"
                            className="iconBtn"
                            // onClick={() => handlePublish(row)}
                            onClick={() => handleDownloadCertificateAfterPublish(row)}

                          >Download
                          </button>}
                        </div>
                        :
                        moduleType !== "confirugationList" ?
                          <div className="actionColumn maxWidth d-flex confirugationListActionColumn">
                            <button
                              type="button"
                              onClick={() => {
                                popupIntentionallyOpenedRef.current = true; // Indicate the popup was intentionally opened
                                setPopupIndex((prevIndex) => {
                                  return prevIndex === rowIndex ? -1 : rowIndex;
                                });
                              }}
                              aria-label="Toggle popup"
                              className="invisible-button"
                            >
                              <i className="bi bi-three-dots-vertical"></i>
                            </button>
                            <span ref={popupOptionsRef}>
                              {popupIndex === rowIndex ? (
                                <PopupOptions
                                  section={section}
                                  popupActions={filteredAction}
                                  setPopupIndex={setPopupIndex}
                                  getAllSubListingdata={getAllSubListingdata}
                                  id={
                                    moduleType === "sampleinward"
                                      ? row["smpl_jrf_id"]
                                      : row["jrf_id"]
                                  }
                                  sampleInwardFormId={row["smpl_inwrd_id"]}
                                  row={row}
                                  formConfig={formConfig}
                                  model={responseData.model}
                                  isBottom={isBottom}
                                  status={getStatus(formConfig, row)}
                                  setDontClick={setDontClick}
                                  from="subListTable"
                                />
                              ) : null}
                            </span>
                            {!["posted", "input-completed"].includes(
                              row["status"]
                            ) && (
                                <div className="">
                                  <button
                                    type="button"
                                    className="iconBtn"
                                    onClick={() => handleOnClick(row)}
                                  >
                                    Edit
                                  </button>
                                </div>
                              )}
                            {["posted", "input-completed"].includes(
                              row["status"]
                            ) && <div>
                                <button
                                  type="button"
                                  className="iconBtn"
                                  onClick={() => handleCertificate(row)}
                                >
                                  Certificate
                                </button>
                              </div>}
                          </div>
                          :

                          <div className="actionColumn maxWidth d-flex gap-8">
                            {row["status"] === "saved" && <div>
                              <button
                                type="button"
                                className="iconBtn"
                                onClick={() => handleCreateConfirugation(row["status"], row["rpc_id"])}
                              >
                                Configure Certificate
                              </button>
                            </div>
                            }
                            {row["status"] === "configured" && <div>
                              <button
                                type="button"
                                className="iconBtn"
                                onClick={() => handleGenerateConfirugation(row)}
                              >
                                Generate Certificate
                              </button>
                            </div>}

                            {row["status"] === "completed" &&
                              <div>
                                <button
                                  type="button"
                                  className="iconBtn"
                                  onClick={() => handleDownloadCertificate(row)}
                                >
                                  Download
                                </button>
                              </div>}
                          </div>
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="previous_next_btns">
          <button
            type="button"
            onClick={() => handlePrev()}
            disabled={currentPage === 1}
            className={currentPage === 1 ? "disabled_btn" : null}
            aria-label="Previous Page"
          >
            <i className="bi bi-chevron-left" aria-hidden="true"></i>
          </button>

          {getPaginationNo()}

          <button
            type="button"
            onClick={() => handleNext()}
            disabled={currentPage === totalPage}
            className={currentPage === totalPage ? "disabled_btn" : null}
            aria-label="Next Page"
          >
            <i className="bi bi-chevron-right" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  );
};
RenderSubListSection.propTypes = {
  section: PropTypes.string,
  sectionIndex: PropTypes.number,
  actions: PropTypes.arrayOf(PropTypes.object),
  responseData: PropTypes.object,
  getAllSubListingdata: PropTypes.func,
  formConfig: PropTypes.object,
  statusCounts: PropTypes.object,
  setIsRejectPopupOpen: PropTypes.func,
  setJRFCreationType: PropTypes.func,
  setIsPopupOpen: PropTypes.func,
  loadingTable: PropTypes.bool,
  setIsOverlayLoader: PropTypes.func,
  moduleType: PropTypes.string,
  formData: PropTypes.object,
};
export default RenderSubListSection;
